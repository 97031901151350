import firebase from "firebase/app";
import "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const app = firebase.initializeApp({
  apiKey: "AIzaSyDeQwLk0D6Xai9ATFlUhExQtMKDVGuW-hA",
  authDomain: "pundit-analytics.firebaseapp.com",
  databaseURL: "https://pundit-analytics.firebaseio.com",
  projectId: "pundit-analytics",
  storageBucket: "pundit-analytics.appspot.com",
  messagingSenderId: "389691317199",
  appId: "1:389691317199:web:83de7d3daf9421f7ced0ba",
  measurementId: "G-7XWN6D7ETT",
});

export default app;
