import React from "react";
import { connect } from "react-redux";

import { Helmet } from "react-helmet";

import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route } from "react-router-dom";
// import { AuthProvider } from "./authentication";
// import PrivateRoute from "./PrivateRoute";
import maTheme from "./theme";
import Routes from "./routes/Routes";
import { AppStateType } from "./redux/reducers";
import { ThemeInitialStateType } from "./redux/reducers/themeReducers";
// import firebase from "firebase/app";

// import "firebase/auth";
function App({ theme }: { theme: ThemeInitialStateType }) {
  return (
    <React.Fragment>
      <Helmet
        titleTemplate="Pundit Analytics"
        defaultTitle="Pundit Analytics"
      />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
            <ThemeProvider theme={maTheme[theme.currentTheme]}>
              <Routes />
              {/* <PrivateRoute exact path="/" component={Routes} /> */}
              {/* <Route exact path="/signin" component={SignIn} />
                <Route exact path="/signup" component={SignUp} /> */}
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default connect((store: AppStateType) => ({
  theme: store.themeReducer,
}))(App);
