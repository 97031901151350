import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
} from "react-router-dom";
import { dashboardLayoutRoutes, authLayoutRoutes } from "./index";
import Component from "react";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import { RouteInfoType } from "../types/types";

import firebase from "firebase/app";

import Loader from "../components/Loader";
import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer,
} from "@react-firebase/auth";
import { IfFirebaseAuthed, IfFirebaseUnAuthed } from "@react-firebase/auth";
import "firebase/auth";

import { Redirect } from "react-router-dom";
import { Mixpanel } from "../Mixpanel";

var config = {
  apiKey: "AIzaSyDeQwLk0D6Xai9ATFlUhExQtMKDVGuW-hA",
  authDomain: "pundit-analytics.firebaseapp.com",
  databaseURL: "https://pundit-analytics.firebaseio.com",
  projectId: "pundit-analytics",
  storageBucket: "pundit-analytics.appspot.com",
  messagingSenderId: "389691317199",
  appId: "1:389691317199:web:83de7d3daf9421f7ced0ba",
  measurementId: "G-7XWN6D7ETT",
};

const childRoutes = (Layout: React.ElementType, routes: Array<RouteInfoType>) =>
  routes.map(({ component: Component, children, path }, index: number) => {
    return children ? (
      children.map((element, index: number) => (
        <Route
          key={index}
          path={element.path}
          exact
          render={(props: RouteComponentProps) => (
            <Layout>
              <element.component {...props} />
            </Layout>
          )}
        />
      ))
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    ) : null;
  });

class Routes extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    // firebase.apps;
    // this.state = {
    //   loading: true,
    //   isSignedInInitial: firebase.initializeApp(config).name auth().currentUser !== null,
    // };
  }
  render() {
    return (
      <Router>
        <Switch>
          <FirebaseAuthProvider {...config} firebase={firebase}>
            {childRoutes(AuthLayout, authLayoutRoutes)}

            <FirebaseAuthConsumer>
              {({ isSignedIn, user }) => {
                if (localStorage.getItem("user") === "true" && isSignedIn) {
                  Mixpanel.identify(user["uid"]);
                  Mixpanel.track("Loaded dashboard");
                  return childRoutes(DashboardLayout, dashboardLayoutRoutes);
                } else if (
                  localStorage.getItem("user") === "true" &&
                  !isSignedIn
                ) {
                  return <Loader></Loader>;
                } else {
                  return <Redirect to={"/auth/sign-in"}></Redirect>;
                }
              }}
            </FirebaseAuthConsumer>
          </FirebaseAuthProvider>
        </Switch>
      </Router>
    );
  }
}

export default Routes;
