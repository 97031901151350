import React from "react";

import async from "../components/Async";

import { Sliders, User, Users } from "react-feather";
import { House, Settings as SettingsIcon, Search } from "@material-ui/icons";
import PostAddIcon from "@material-ui/icons/PostAdd";
// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));
const UserActivityFeed = async(
	() => import("../pages/dashboards/UserActivityFeed")
);
const PowerSearch = async(() => import("../pages/dashboards/PowerSearch"));

const Settings = async(() => import("../pages/dashboards/Settings"));

const Analytics = async(() => import("../pages/dashboards/Analytics"));

const Profile = async(() => import("../pages/pages/Profile"));
const TargetArticles = async(() => import("../pages/pages/Target_Articles"));

const TargetEmail = async(() => import("../pages/pages/Target_Email"));

const TargetIssue = async(() => import("../pages/pages/Target_Issue"));
const Target_VisualPing_Job = async(
	() => import("../pages/pages/Target_VisualPing_Job")
);

const dashboardsRoutes = {
	id: "Home",
	path: "/",
	header: "Home",
	icon: <House />,
	containsHome: true,
	children: null,
	component: Default,
};
const activityFeedRoutes = {
	id: "Activity Feed",
	path: "/activity_feed",
	header: "Home",
	icon: <PostAddIcon />,
	containsHome: true,
	children: null,
	component: UserActivityFeed,
};
const powerSearchRoutes = {
	id: "Search",
	path: "/search",
	header: "Home",
	icon: <Search />,
	containsHome: true,
	children: null,
	component: PowerSearch,
};

const settingsRoutes = {
	id: "Settings",
	path: "/settings",
	header: "Home",
	icon: <SettingsIcon />,
	containsHome: true,
	children: null,
	component: Settings,
};

// const pagesRoutes = {
//   id: "Pages",
//   path: "/pages",
//   icon: <Layout />,
//   children: [
//     {
//       path: "/pages/settings",
//       name: "Settings",
//       component: Settings
//     },
//     {
//       path: "/pages/pricing",
//       name: "Pricing",
//       component: Pricing
//     },
//     {
//       path: "/pages/blank",
//       name: "Blank Page",
//       component: Blank
//     }
//   ],
//   component: null
// };

const profileRoutes = {
	id: "Profile",
	path: "/profile",
	icon: <User />,
	component: Profile,
	children: [
		{
			path: "/profile/:target_id",
			name: "Home",
			component: Profile,
		},
		{
			path: "/profile/:target_id/articles",
			name: "Articles",
			component: TargetArticles,
		},
		{
			path: "/profile/:target_id/web_changes",
			name: "Website Changes",
			component: Profile,
		},
		{
			path: "/profile/:target_id/web_changes/:job_id",
			name: "Website Changes Job",
			component: Target_VisualPing_Job,
		},
		{
			path: "/profile/:target_id/tweets",
			name: "Tweets",
			component: Profile,
		},
		{
			path: "/profile/:target_id/fb_ads",
			name: "Facebook Ads",
			component: Profile,
		},
		{
			path: "/profile/:target_id/emails",
			name: "Emails",
			component: Profile,
		},
		{
			path: "/profile/:target_id/mentions",
			name: "Mentions",
			component: Profile,
		},
		{
			path: "/profile/:target_id/emails/:email_id",
			name: "Email",
			component: TargetEmail,
		},
		{
			path: "/profile/:target_id/issue/:issue_id",
			name: "Issue",
			component: TargetIssue,
		},
	],
};

// const projectsRoutes = {
//   id: "Projects",
//   path: "/projects",
//   icon: <Briefcase />,
//   badge: "8",
//   component: Projects,
//   children: null
// };

// const invoiceRoutes = {
//   id: "Invoices",
//   path: "/invoices",
//   icon: <CreditCard />,
//   children: [
//     {
//       path: "/invoices",
//       name: "List",
//       component: InvoiceList
//     },
//     {
//       path: "/invoices/detail",
//       name: "Details",
//       component: InvoiceDetails
//     }
//   ],
//   component: null
// };

// const orderRoutes = {
//   id: "Orders",
//   path: "/orders",
//   icon: <ShoppingCart />,
//   component: Orders,
//   children: null
// };

// const tasksRoutes = {
//   id: "Tasks",
//   path: "/tasks",
//   icon: <CheckSquare />,
//   badge: "17",
//   component: Tasks,
//   children: null
// };

// const calendarRoutes = {
//   id: "Calendar",
//   path: "/calendar",
//   icon: <CalendarIcon />,
//   component: Calendar,
//   children: null
// };

const authRoutes = {
	id: "Auth",
	path: "/auth",
	icon: <Users />,
	children: [
		{
			path: "/auth/sign-in",
			name: "Sign In",
			component: SignIn,
		},
		// {
		//   path: "/auth/sign-up",
		//   name: "Sign Up",
		//   component: SignUp,
		// },
		// {
		//   path: "/auth/reset-password",
		//   name: "Reset Password",
		//   component: ResetPassword,
		// },
		// {
		//   path: "/auth/404",
		//   name: "404 Page",
		//   component: Page404,
		// },
		// {
		//   path: "/auth/500",
		//   name: "500 Page",
		//   component: Page500,
		// },
	],
	component: null,
};

// const componentsRoutes = {
//   id: "Components",
//   path: "/components",
//   header: "Elements",
//   icon: <Grid />,
//   children: [
//     {
//       path: "/components/alerts",
//       name: "Alerts",
//       component: Alerts
//     },
//     {
//       path: "/components/avatars",
//       name: "Avatars",
//       component: Avatars
//     },
//     {
//       path: "/components/badges",
//       name: "Badges",
//       component: Badges
//     },
//     {
//       path: "/components/buttons",
//       name: "Buttons",
//       component: Buttons
//     },
//     {
//       path: "/components/cards",
//       name: "Cards",
//       component: Cards
//     },
//     {
//       path: "/components/chips",
//       name: "Chips",
//       component: Chips
//     },
//     {
//       path: "/components/dialogs",
//       name: "Dialogs",
//       component: Dialogs
//     },
//     {
//       path: "/components/expansion-panels",
//       name: "Expansion Panels",
//       component: ExpPanels
//     },
//     {
//       path: "/components/lists",
//       name: "Lists",
//       component: Lists
//     },
//     {
//       path: "/components/menus",
//       name: "Menus",
//       component: Menus
//     },
//     {
//       path: "/components/pagination",
//       name: "Pagination",
//       component: Pagination
//     },
//     {
//       path: "/components/progress",
//       name: "Progress",
//       component: Progress
//     },
//     {
//       path: "/components/snackbars",
//       name: "Snackbars",
//       component: Snackbars
//     },
//     {
//       path: "/components/tooltips",
//       name: "Tooltips",
//       component: Tooltips
//     }
//   ],
//   component: null
// };

// const formsRoutes = {
//   id: "Forms",
//   path: "/forms",
//   icon: <CheckSquare />,
//   children: [
//     {
//       path: "/forms/pickers",
//       name: "Pickers",
//       component: Pickers
//     },
//     {
//       path: "/forms/selection-controls",
//       name: "Selection Controls",
//       component: SelectionCtrls
//     },
//     {
//       path: "/forms/selects",
//       name: "Selects",
//       component: Selects
//     },
//     {
//       path: "/forms/text-fields",
//       name: "Text Fields",
//       component: TextFields
//     },
//     {
//       path: "/forms/dropzone",
//       name: "Dropzone",
//       component: Dropzone
//     },
//     {
//       path: "/forms/editors",
//       name: "Editors",
//       component: Editors
//     }
//   ],
//   component: null
// };

// const tablesRoutes = {
//   id: "Tables",
//   path: "/tables",
//   icon: <List />,
//   children: [
//     {
//       path: "/tables/simple-table",
//       name: "Simple Table",
//       component: SimpleTable
//     },
//     {
//       path: "/tables/advanced-table",
//       name: "Advanced Table",
//       component: AdvancedTable
//     }
//   ],
//   component: null
// };

// const iconsRoutes = {
//   id: "Icons",
//   path: "/icons",
//   icon: <Heart />,
//   children: [
//     {
//       path: "/icons/material-icons",
//       name: "Material Icons",
//       component: MaterialIcons
//     },
//     {
//       path: "/icons/feather-icons",
//       name: "Feather Icons",
//       component: FeatherIcons
//     }
//   ],
//   component: null
// };

// const chartRoutes = {
//   id: "Charts",
//   path: "/charts",
//   icon: <PieChart />,
//   component: Chartjs,
//   children: null
// };

// const mapsRoutes = {
//   id: "Maps",
//   path: "/maps",
//   icon: <Map />,
//   children: [
//     {
//       path: "/maps/google-maps",
//       name: "Google Maps",
//       component: GoogleMaps
//     },
//     {
//       path: "/maps/vector-maps",
//       name: "Vector Maps",
//       component: VectorMaps
//     }
//   ],
//   component: null
// };

// const presentationRoutes = {
//   id: "Presentation",
//   path: "/",
//   header: "Docs",
//   icon: <Monitor />,
//   component: Presentation,
//   children: null
// };

// const documentationRoutes = {
//   id: "Documentation",
//   path: "/documentation",
//   icon: <BookOpen />,
//   children: [
//     {
//       path: "/documentation/welcome",
//       name: "Welcome",
//       component: Welcome
//     },
//     {
//       path: "/documentation/getting-started",
//       name: "Getting Started",
//       component: GettingStarted
//     },
//     {
//       path: "/documentation/environment-variables",
//       name: "Environment Variables",
//       component: EnvironmentVariables
//     },
//     {
//       path: "/documentation/deployment",
//       name: "Deployment",
//       component: Deployment
//     },
//     {
//       path: "/documentation/theming",
//       name: "Theming",
//       component: Theming
//     },
//     {
//       path: "/documentation/state-management",
//       name: "State Management",
//       component: StateManagement
//     },
//     {
//       path: "/documentation/support",
//       name: "Support",
//       component: Support
//     },
//   ],
//   component: null
// };

// const changelogRoutes = {
//   id: "Changelog",
//   path: "/changelog",
//   badge: "v1.2.0",
//   icon: <List />,
//   component: Changelog,
//   children: null
// };

// // This route is not visisble in the sidebar
// const privateRoutes = {
//   id: "Private",
//   path: "/private",
//   component: Blank,
//   children: null,
// };

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
	dashboardsRoutes,
	// pagesRoutes,
	powerSearchRoutes,
	activityFeedRoutes,
	profileRoutes,
	settingsRoutes,
	// projectsRoutes,
	// orderRoutes,
	// invoiceRoutes,
	// tasksRoutes,
	// calendarRoutes,
	// componentsRoutes,
	// chartRoutes,
	// formsRoutes,
	// tablesRoutes,
	// iconsRoutes,
	// mapsRoutes,
	// documentationRoutes,
	// changelogRoutes,
	// presentationRoutes,
	// privateRoutes
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
	dashboardsRoutes,
	activityFeedRoutes,
	powerSearchRoutes,
	settingsRoutes,
	// pagesRoutes,
	// profileRoutes,
	// projectsRoutes,
	// orderRoutes,
	// invoiceRoutes,
	// tasksRoutes,
	// calendarRoutes,
	// authRoutes,
	// componentsRoutes,
	// chartRoutes,
	// formsRoutes,
	// tablesRoutes,
	// iconsRoutes,
	// mapsRoutes,
	// presentationRoutes,
	// documentationRoutes,
	// changelogRoutes
];
